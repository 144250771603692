.App {
    padding: 0;
}

/*.loader-overlay {*/
/*    position: fixed;*/
/*    top: 0;*/
/*    left: 0;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    background-color: rgba(255, 255, 255, 0.7); !* Прозрачный белый фон *!*/
/*    z-index: 1000; !* Задний план над всеми остальными элементами *!*/
/*}*/

/*.loader-message {*/
/*    margin-top: 10px;*/
/*    font-size: 16px;*/
/*    color: #333; !* Цвет текста сообщения *!*/
/*}*/

.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    opacity: 0;
    /*visibility: hidden;*/
    transition: opacity 0.5s ease-in-out;
}

.loader-overlay.visible {
    opacity: 1;
    /*visibility: visible;*/
}


.loader-message {
    margin-top: 10px;
    font-size: 16px;
    color: #333; /* Цвет текста сообщения */
}